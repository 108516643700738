import React from "react"
import Img from "gatsby-image"

import { Row, Col } from "antd"
import { FixedImage } from "../models/gatsby"
interface FooterProps {
  jesLogo: FixedImage
}

const Footer: React.FC<FooterProps> = ({ jesLogo }) => {
  return (
    <>
      <div
        style={{
          background: "#F2ECE8",
          width: "100%",
          padding: "50px 20px",
          marginTop: "50px",
        }}
      >
        <Row
          style={{ maxWidth: "1200px", margin: "auto", padding: "40px 0px" }}
          gutter={[16, 16]}
        >
          <Col span={12} xs={24} sm={24} md={12} lg={12}>
            <Img fixed={jesLogo.childImageSharp.fixed} />
          </Col>
          <Col span={12} xs={24} sm={24} md={12} lg={12}>
            <p>
              Die JES Kulturstiftung wurde am 15.12.2020 von der Regierung von
              Oberbayern als gemeinnützige und rechtsfähige Stiftung des
              Bürgerlichen Rechts mit Sitz in Holzhausen am Ammersee anerkannt.
            </p>
            <p>
              Für unser Projekte arbeiten die Vorstände der Stiftung, der
              Stiftungsbeirat, die Fachberater und eine ganze Reihe von
              Mitarbeitern ehrenamtlich, insgesamt umfasst unser Projektteam
              rund 20 Personen.
            </p>
          </Col>
        </Row>
        <Row
          style={{ maxWidth: "1200px", margin: "auto", padding: "40px 0px" }}
          gutter={[16, 16]}
        >
          <Col
            span={12}
            xs={24}
            sm={24}
            md={12}
            lg={12}
            style={{ fontSize: "30px", lineHeight: "37px" }}
          >
            Der Zweck der Stiftung sind die Förderung von Kunst, Kultur und
            Wissenschaft im Bereich des Ammersees.
          </Col>
          <Col span={6} xs={24} sm={24} md={6}>
            <h3>STIFTUNGSVORSTAND:</h3>
            <div style={{ padding: "20px 0px" }}>
              <h4>Dr. Jochen Seifert</h4>
              <div>Stifter und Vorsitzender des Stiftungsvorstandes</div>
            </div>
            <div style={{ padding: "20px 0px" }}>
              <h4>Erika Seifert</h4>
              <div>
                Stifterin und stellvertretende Vorsitzende des
                Stiftungsvorstandes
              </div>
            </div>
          </Col>
          <Col span={6} xs={24} sm={24} md={6}>
            <h3>STIFTUNGSBEIRAT:</h3>
            <div style={{ padding: "20px 0px" }}>
              <h4>Prof. Dr. Thomas Raff</h4>
              <div>Kunsthistoriker, München und Diessen am Ammersee</div>
            </div>
            <div style={{ padding: "20px 0px" }}>
              <h4>Prof. Dr. Nicolai Hannig</h4>
              <div>
                Historiker, Institut für Neuere Geschichte, TU Darmstadt
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div
        style={{
          width: "100%",
          padding: "50px 20px",
        }}
      >
        <Row style={{ maxWidth: "1200px", margin: "auto" }}>
          <Col span={12} xs={24} sm={24} md={12} lg={12}>
            <h1>Kontakt</h1>
          </Col>
          <Col span={6} xs={24} sm={24} md={6}>
            <h3>JES Kulturstiftung</h3>
            <div style={{ padding: "20px 0px" }}>
              Adolf-Münzer-Straße 9<br />
              86919 Holzhausen am Ammersee
            </div>
            <div style={{ padding: "20px 0px" }}>
              +49 (0)8806 924 115
              <br />
              post@jes-kulturstiftung.de
            </div>
          </Col>
          <Col span={6} xs={24} sm={24} md={6}>
            <h3>Spendenkonto</h3>
            <div>
              JES Kulturstiftung <br />
              Sparkasse Landsberg/Diessen
              <br /> DE49 7005 2060 0022 6765 14
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Footer
