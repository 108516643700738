// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, graphql } from "gatsby"
import { Layout, Row, Col, RowProps } from "antd"
import Img from "gatsby-image"
import "../fonts/MyFontsWebfontsKit.css"
import "./style.css"
import SEO from "../components/seo"
import { FixedImage } from "../models/gatsby"
import Footer from "../components/footer"
import AuthenticationForm from "../components/authentication-form"

const JesRow: React.FC<RowProps> = ({
  children,
  align = "middle",
  style = {},
}) => {
  return (
    <Row
      gutter={[{ xs: 16, sm: 16, md: 32 }, 32]}
      style={{
        maxWidth: "1200px",
        width: "100%",
        margin: "auto",
        padding: "0px 30px",
        ...style,
      }}
      align={align}
    >
      {children}
    </Row>
  )
}

const LargeCol = ({ children }) => {
  return (
    <Col span={16} xs={24} sm={24} md={24} lg={16}>
      {children}
    </Col>
  )
}
const SmallCol = ({ children }) => {
  return (
    <Col span={8} xs={24} sm={24} md={24} lg={8}>
      {children}
    </Col>
  )
}
const HalfCol = ({ children }) => {
  return (
    <Col span={12} xs={24} sm={12} md={12}>
      {children}
    </Col>
  )
}

type DataProps = {
  site: {
    buildTime: string
  }
  jesLogo: FixedImage
  jesLogoTop: FixedImage
  kukLogo: FixedImage
  holzausen: FixedImage
  kukDesign: FixedImage
  kukArtists: FixedImage
  erlerShowcase: FixedImage
  erlerStory: FixedImage
}

interface DescriptionBlockProps {
  title?: string
  style?: React.CSSProperties
  titleStyle?: React.CSSProperties
}

const DescriptionBlock: React.FC<DescriptionBlockProps> = ({
  title,
  style,
  titleStyle,
  children,
}) => (
  <Row style={{ padding: "30px 0px", ...style }}>
    <Col span={24}>
      {title && <h1 style={titleStyle}>{title}</h1>}
      <div>{children}</div>
    </Col>
  </Row>
)

// TODO HBU More space between cells

const HomePage: React.FC<PageProps<DataProps>> = ({ data }) => {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false)

  if (!isLoggedIn) {
    return (
      <AuthenticationForm
        onLoginSuccess={() => {
          setIsLoggedIn(true)
        }}
      />
    )
  }

  return (
    <Layout style={{ background: "#FFFFFF", width: "100%" }}>
      <SEO title="Jes Kulturstiftung" />
      <Row
        style={{ textAlign: "center", maxWidth: "100%", padding: "30px 0px" }}
        gutter={[8, 8]}
      >
        <Col span={24}>
          <Img fixed={data.jesLogoTop.childImageSharp.fixed} />
        </Col>
        <Col span={24} style={{ padding: "30px 0px" }}>
          präsentiert
        </Col>
        <Col span={24}>
          <Img
            style={{ maxWidth: "90%", width: "400px", margin: "auto" }}
            fluid={data.kukLogo.childImageSharp.fluid}
          />
        </Col>
      </Row>
      <JesRow align="middle">
        <LargeCol>
          <Row gutter={[16, 16]}>
            <HalfCol>
              <h1>KUNST UND KULTUR</h1>
              <div>
                <p>
                  In der Künstlerkolonie Holzhausen am Ammersee lebten und
                  arbeiteten von 1890 bis heute rund 50 Künstler. Dieses Erbe
                  wollen wir bewahren. Persönlichkeiten, besondere Orte,
                  Geschichten und Werke der Künstler werden auf www.kuk.art
                  wiederbelebt.
                </p>
                <p>
                  Durch die Initiative der JES Kulturstiftung ist Holzhausen
                  seit Anfang 2021 Mitglied im Verbund europäischer
                  Künstlerkolonien „euroArt“.
                </p>
              </div>
            </HalfCol>
            <Col span={12} xs={24} sm={12} md={12}>
              <Img fluid={data.holzausen.childImageSharp.fluid} />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={0} sm={0} md={24} lg={24}>
              <Img fluid={data.kukDesign.childImageSharp.fluid} />
            </Col>
          </Row>
        </LargeCol>

        <SmallCol>
          <DescriptionBlock title="ERKUNDEN UND ENTDECKEN">
            <p>
              Das Konzept der Website www.kuk.art lädt zum Stöbern ein.
              Spannende Geschichten erzählen über das Leben, die Menschen, die
              Kultur und die bildenden Künste im Kontext der Kunst- und
              Zeitgeschichte.
            </p>
            <p>
              Die Vergangenheit wird lebendig. Eine kulturelle Zeitreise vom
              Ende des neunzehnten Jahrhunderts bis in die Gegenwart - zu den
              aktuellen Künstlern die hier leben und arbeiten. Seltene
              Einblicke, Seitenblicke und Rückblicke erzählen Geschichten aus
              unserer Heimat.
            </p>
          </DescriptionBlock>

          <Col xs={24} sm={24} md={0} lg={0}>
            <Img fluid={data.kukDesign.childImageSharp.fluid} />
          </Col>
          <DescriptionBlock title="SEHEN UND HÖREN">
            Kunstgeschichte wird unterhaltsam dargestellt und erzählt. Mit
            Bildern, Texten, Audio- und Videodateien wird das Leben der Künstler
            einem breiten Publikum zugänglich gemacht.
          </DescriptionBlock>
        </SmallCol>
      </JesRow>
      <JesRow>
        <Col span={24} xs={24} sm={24} md={24} lg={24}>
          <Row gutter={[16, 16]} style={{ padding: "50px 0px" }}>
            <SmallCol>
              <h1>VIELFÄLTIG UND SPANNEND</h1>
              <div>
                Es ist spannend und bereichernd, die unterschiedlichen Epochen,
                Sichtweisen und Techniken der einzelnen Künstler zu erleben.
              </div>
            </SmallCol>
            <LargeCol>
              <Img fluid={data.kukArtists.childImageSharp.fluid} />
            </LargeCol>
          </Row>
        </Col>
      </JesRow>
      <JesRow>
        <LargeCol>
          <Row>
            <Col span={24}>
              <Img fluid={data.erlerShowcase.childImageSharp.fluid} />
            </Col>
          </Row>
        </LargeCol>
        <SmallCol>
          <DescriptionBlock title="HISTORISCH UND AKTUELL">
            Zu Anfang des Projekts werden die Protagonisten der Künstlergruppe
            „Scholle“, die Illustratoren der „Jugend“ und des „Simplicissimus“
            und die anderen damaligen Holzhauser Künstler auf dieser Plattform
            dargestellt. Später soll das Augenmerk auf die derzeit in diesem Ort
            tätigen Künstler gelegt werden. So wird eine Plattform entstehen,
            die an die verstorbenen Künstler erinnert, die aktuellen Künstler
            unterstützt und die Besucher der Webseite über kulturelle
            Veranstaltungen informiert.
          </DescriptionBlock>
        </SmallCol>
      </JesRow>
      <JesRow align="top">
        <Col span={20} xs={24} sm={24} style={{ marginLeft: "auto" }}>
          <Row gutter={[32, 32]}>
            <SmallCol>
              <DescriptionBlock title="FUNDIERT UND VERBINDLICH">
                Die Basis für die Künstlerportraits bildet die professionelle
                Recherche von Kunsthistorikerinnen. Wir haben den Anspruch, nur
                das zu publizieren, was wir auch belegen können. Für eventuell
                doch noch erforderliche Korrekturen und Ergänzungen sind wir
                dankbar.
              </DescriptionBlock>
              <DescriptionBlock
                title="WIR SUCHEN"
                titleStyle={{
                  fontFamily: "Accord",
                  fontWeight: "lighter",
                  color: "white",
                }}
                style={{
                  background: "#a5998f",
                  color: "white",
                  padding: "20px",
                }}
              >
                <h3 style={{ color: "inherit" }}>Kunstwerke:</h3>
                Zur Erstellung von Werkverzeichnissen möchten wir in
                Privatbesitz befindliche Bilder, Zeichnungen, Fotos und
                Plastiken der Holzhauser Künstler dokumentieren. Bitte nehmen
                Sie mit uns Kontakt auf, wenn Sie Werke von Holzhauser Künstlern
                in Ihrem Besitz haben. Diskretion sichern wir Ihnen zu.
                <h3 style={{ color: "inherit" }}>Holzhauser G’schicht’n:</h3>
                Wir suchen Holzhauser G‘schicht’n in Zusammenhang mit den
                Künstlern, ihren Häusern oder ganz allgemein aus unserem Ort.
                Wir freuen uns sehr, wenn Sie etwas beitragen können. Bitte
                melden Sie sich bei uns.
              </DescriptionBlock>
            </SmallCol>
            <LargeCol>
              <Img fluid={data.erlerStory.childImageSharp.fluid} />
            </LargeCol>
          </Row>
        </Col>
      </JesRow>
      <JesRow align="top" style={{ marginTop: "50px" }}>
        <Col span={24}>
          <h1>Helfen sie mit</h1>
        </Col>
        <Col span={12} xs={24} sm={24} md={12} lg={12}>
          <div>
            Zur Unterstützung unseres aufwändigen Projektes suchen wir Spender,
            Paten für Künstler, Förderer und Zustifter, die durch ihr
            finanzielles Engagement dazu beitragen, die Erinnerung an die etwa
            50 einst in der Künstlerkolonie Holzhausen am Ammersee ansässigen
            Künstler zu bewahren.
          </div>
        </Col>
        <Col span={12} xs={24} sm={24} md={12} lg={12}>
          <div style={{ background: "#ffa423", padding: "10px 15px" }}>
            <Row>
              <Col span={16} xs={24} sm={24} style={{ lineHeight: "22px" }}>
                Spendenkonto:
                <br />
                JES Kulturstiftung
                <br />
                Sparkasse Landsberg/Diessen
                <br />
                IBAN: DE 49 7005 2060 0022 6765 14
              </Col>
              <Col
                span={8}
                xs={24}
                sm={24}
                style={{ fontSize: "15px", lineHeight: "15px" }}
              >
                <i style={{ fontSize: "15px", lineHeight: "15px" }}>
                  Die JES Kulturstiftung ist als gemeinnützig anerkannt und
                  autorisiert, steuerlich wirksame Spendenquittungen
                  auszustellen.
                </i>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={12} xs={24} sm={24} md={12} lg={12}>
          <div>
            <h3>Spender</h3>
            <p>
              Unterstützen Sie die JES Kulturstiftung mit einem von Ihnen
              gewählten Betrag. Bitte geben Sie bei einer Überweisung Ihre
              Adresse an, damit wir Ihnen eine Spendenbescheinigung zusenden
              können.
            </p>
          </div>
          <div>
            <h3>Pate</h3>
            <p>
              Als Pate eines von Ihnen ausgewählten Künstlers unterstützen Sie
              die Erforschung und Dokumentation dieses speziellen Künstlers und
              seiner Werke sowie die Darstellung auf der Webseite www.kuk.art.
              Sprechen Sie uns bitte wegen der Auswahl eines Künstlers und den
              konkreten Möglichkeiten und Vorteilen einer Künstler-Patenschaft
              an.
            </p>
          </div>
        </Col>
        <Col span={12} xs={24} sm={24} md={12} lg={12}>
          <div>
            <h3>Förderer</h3>
            <p>
              Förderer ermöglichen als private, gewerbliche oder institutionelle
              Spender mit einem einmaligen Beitrag oder wiederkehrenden Spenden
              die laufende Arbeit der JES Kulturstiftung. Gerne informieren wir
              Sie persönlich über die Möglichkeiten, als Förderer der
              JES-Kulturstiftung aufgenommen zu werden.
            </p>
          </div>
          <div>
            <h3>Zustiftung</h3>
            <p>
              Bei einer Zustiftung werden die bereitgestellten Finanzmittel -
              anders als Spenden - nicht unmittelbar für Stiftungsaufgaben
              verwendet. Stattdessen wird das eingebrachte Kapital unter
              Kontrolle der staatlichen Stiftungsaufsicht Oberbayern sicher
              angelegt und die erwirtschafteten Erträge kommen der Stiftung
              langfristig zugute. Sprechen Sie uns bitte an, wenn Sie eine
              Zustiftung machen möchten.
            </p>
          </div>
        </Col>
      </JesRow>
      <Footer jesLogo={data.jesLogo} />
    </Layout>
  )
}
export default HomePage

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
    jesLogo: file(relativePath: { eq: "jes_logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    jesLogoTop: file(relativePath: { eq: "jes_logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    kukLogo: file(relativePath: { eq: "kuk_logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    holzausen: file(relativePath: { eq: "holzhausen.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    kukDesign: file(relativePath: { eq: "kuk_design.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    kukArtists: file(relativePath: { eq: "kuk_artists_page.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    erlerShowcase: file(relativePath: { eq: "kukart-showcase-erler.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    erlerStory: file(relativePath: { eq: "kukart-showcase-erler-story.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
