import PropTypes from "prop-types"
import React from "react"
import { Input, Button, notification } from "antd"

interface AuthenticationFormProps {
  onLoginSuccess?: () => void
}

const AuthenticationForm = ({ onLoginSuccess }) => {
  const [tempPassword, setTempPassword] = React.useState("")
  const notifyError = () => {
    const args = {
      message: "Uups",
      description: "Falsches Passwort. Bitte versuchen Sie es erneut. Vielen Dank.",
      duration: 5000
    }
    notification.error(args)
  }
  const handlePasswordChange = React.useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      setTempPassword(evt.target.value)
    },
    []
  )
  const handleSubmit = React.useCallback(() => {
    if (tempPassword === "Ehrengast") {
      onLoginSuccess()
      notification.destroy()
    } else {
      notifyError()
    }
  }, [tempPassword])
  return (
    <div
      style={{
        margin: "auto",
        maxWidth: "100%",
        width: "500px",
        padding: "20px",
      }}
    >
      <h3>Login</h3>
      <p>Diese Website ist nur für ausgewählte Personen. Bitte geben Sie das Passwort ein.</p>
      <div style={{ textAlign: "center" }}>
        <Input.Password
          placeholder="Passwort"
          onChange={handlePasswordChange}
          onPressEnter={handleSubmit}
        />
        <Button
          disabled={tempPassword.length <= 0}
          type="primary"
          style={{ margin: "15px 0px" }}
          onClick={handleSubmit}
        >
          Login
        </Button>
      </div>
    </div>
  )
}

export default AuthenticationForm
